import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Mobile from "./Mobile";
import { updateWorkSpace } from "../context/WorkSpace";

export default function PrimarySearchAppBar({ path }) {
    const [workSpace] = useContext(updateWorkSpace);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const navigate = useNavigate();
    const Logout = () => {
        sessionStorage.clear();
        navigate("/");
    };

    const Settings = () => {
        navigate("/settings");
    };

    const WorkSpace = () => {
        navigate("/workspace");
    };

    const menuId = "primary-search-account-menu";
    const renderMenu = (
        <Menu
            id={menuId}
            anchorEl={anchorEl}
            keepMounted
            open={isMenuOpen}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
            <MenuItem onClick={handleMenuClose}>
                <div onClick={WorkSpace}>WorkSpace</div>
            </MenuItem>
            {workSpace === "hdp" && (
                <MenuItem onClick={handleMenuClose}>
                    <div onClick={Settings}>Settings</div>
                </MenuItem>
            )}
            <MenuItem onClick={handleMenuClose}>
                <div onClick={Logout}>Logout</div>
            </MenuItem>
        </Menu>
    );

    return (
        <Box sx={{ flexGrow: 1 }} className="stay-nav-bar">
            <AppBar position="static">
                <Toolbar>
                    <div className="nav_mobile">
                        <Mobile />
                    </div>
                    <div className="nav_desktop">
                        <NavLink to="/home" className="nav">
                            <HomeIcon size="large" edge="start" color="inherit" aria-label="open drawer" className="mui_icon"></HomeIcon>
                        </NavLink>
                        {workSpace === "hrp" && (
                            <React.Fragment>
                                <NavLink className="nav" to="/company">
                                    Company
                                </NavLink>
                                <NavLink className="nav" to="/policy">
                                    Policy
                                </NavLink>
                                <NavLink className="nav" to="/university">
                                    University
                                </NavLink>
                                <NavLink className="nav" to="/library-files">
                                    Library
                                </NavLink>
                                <NavLink className="nav" to="/provider">
                                    Provider
                                </NavLink>
                            </React.Fragment>
                        )}
                        {workSpace === "hdp" && (
                            <div style={{ display: "flex" }}>
                                <NavLink className="nav" to="/hotel">
                                    Hotel
                                </NavLink>
                                <NavLink className="nav" to="/booking">
                                    Booking
                                </NavLink>
                                <NavLink className="nav" to="/reports">
                                    Reports
                                </NavLink>
                                <NavLink className="nav" to="/amenities">
                                    Amenities
                                </NavLink>
                                <NavLink className="nav" to="/policy">
                                    Policy
                                </NavLink>
                                <NavLink className="nav" to="/booking-type">
                                    Booking type
                                </NavLink>
                                <NavLink className="nav" to="/set">
                                    Set Hotel
                                </NavLink>
                                <NavLink className="nav" to="/alerts">
                                    Alert page
                                </NavLink>
                                <NavLink className="nav" to="/types">
                                    Hotel Types
                                </NavLink>
                            </div>
                        )}
                        {workSpace === "common" && (
                            <div style={{ display: "flex" }}>
                                <NavLink className="nav" to="/cities">
                                    Cities
                                </NavLink>
                                <NavLink className="nav" to="/regions">
                                    Regions
                                </NavLink>
                                <NavLink className="nav" to="/countries">
                                    Countries
                                </NavLink>
                                <NavLink className="nav" to="/languages">
                                    Languages
                                </NavLink>
                            </div>
                        )}
                    </div>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton size="large" aria-label="show 4 new mails" color="inherit"></IconButton>
                    <IconButton size="large" aria-label="show 17 new notifications" color="inherit"></IconButton>
                    <div className="logo-wrapper">
                        <div className="logo">
                            <span className="logo_item">T</span>
                            <span className="logo_item_center">T</span>
                            <span className="logo_item">S</span>
                        </div>
                        {/* <div className="logo_bottom">Transoxania Technology Solutions</div> */}
                    </div>
                    <IconButton
                        size="large"
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={handleProfileMenuOpen}
                        color="inherit"
                    >
                        <AccountCircle className="mui_icon" />
                    </IconButton>
                    <Box sx={{ display: { xs: "flex", md: "none" } }}>
                        <IconButton size="large" aria-label="show more" aria-haspopup="true" color="inherit"></IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMenu}
        </Box>
    );
}
