import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FormControls, FormControlWrapper, ListBtn, Wrapper } from "../../../styles/ListStyle";
import { FormControl, InputLabel, Select, MenuItem, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import REACT_APP from "../../../environment";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";

const PopularAmenity = () => {
    const [loading, setLoading] = useState(false);
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState("");
    const [amenities, setAmenities] = useState([]);
    const [popularAmenities, setPopularAmenities] = useState([]);
    const [boards, setBoards] = useState([{ id: 1, title: "All Amenities", items: [] }]);
    const [boards2, setBoards2] = useState([{ id: 2, title: "Popular", items: [] }]);
    const [currentBoard, setCurrentBoard] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);

    const dragOverHandler = (e) => {
        e.preventDefault();
    };
    const dragLeaveHandler = (e) => {
        e.target.style.boxShadow = "none";
    };
    const dragStartHandler = (e, board, item) => {
        setCurrentBoard(board);
        setCurrentItem(item);
    };
    const DropHandler = (e, board, item) => {
        e.preventDefault();
        const currentIndex = currentBoard.indexOf(currentItem);
        currentBoard.splice(currentIndex, 1);
        setPopularAmenities([...popularAmenities, currentItem]);
    };
    const DropHandler2 = (e, board, item) => {
        e.preventDefault();
        const currentIndex = currentBoard.indexOf(currentItem);
        currentBoard.splice(currentIndex, 1);
        setAmenities([...amenities, currentItem]);
        setBoards(
            boards.map((b) => {
                if (b.id === board.id) {
                    return board;
                }
                if (b.id === currentBoard.id) {
                    return currentBoard;
                }
                return b;
            })
        );
    };
    const dragEndHandler = (e) => {
        e.target.style.boxShadow = "none";
    };

    function getGroups() {
        axios
            .get(`${REACT_APP.URL}/api/hdp/tts/adm/hotel/amenity/group`, {
                headers: { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.CLIENTID, "client-secret": REACT_APP.SECRET },
            })
            .then((response) => {
                setGroups(response.data.data);
            })
            .catch((err) => {
                toast.error(err.message);
            });
    }

    const handleChange = (e) => {
        setBoards([{ id: 1, title: e.target.value.name, items: [] }]);
        setSelectedGroup(e.target.value);
        axios
            .get(`${REACT_APP.URL}/api/hdp/tts/adm/hotel/amenity-group/${e.target.value.id}/top`, {
                headers: { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.CLIENTID, "client-secret": REACT_APP.SECRET },
            })
            .then((response) => {
                setAmenities(response.data.data.filter((e) => !e.is_popular));
                setPopularAmenities(response.data.data.filter((e) => e.is_popular));
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };

    const onSave = () => {
        setLoading(true);
        const data = popularAmenities.map((e) => e.id);
        console.log(popularAmenities);

        axios
            .put(`${REACT_APP.URL}/api/hdp/tts/adm/hotel/amenity-group/${selectedGroup.id}/top`, data, {
                headers: { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.CLIENTID, "client-secret": REACT_APP.SECRET },
            })
            .then((res) => {
                setLoading(false);
                toast.success("Submitted successfully", { autoClose: 2000 });
            })
            .catch((err) => {
                setLoading(false);
                toast.error("An error occurred");
            });
    };

    useEffect(() => {
        getGroups();
    }, []);

    return (
        <Wrapper>
            <FormControlWrapper>
                <FormControls>
                    <FormControl style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-label">Select Group</InputLabel>
                        <Select
                            placeholder="Select Amenity Group"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedGroup}
                            label="Select Amenity Group"
                            onChange={handleChange}
                        >
                            {groups.map((value, index) => {
                                return (
                                    <MenuItem key={index} value={value}>
                                        {value.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </FormControls>
            </FormControlWrapper>
            <div className="DropContainer">
                {boards.map((value) => {
                    return (
                        <div className="Board" key={value.id} onDragOver={(e) => dragOverHandler(e)} onDrop={(e) => DropHandler2(e, amenities, 1)}>
                            <div className="Board_title" key={value.id}>
                                {value.title}
                            </div>
                            {amenities.map((value) => {
                                return (
                                    <div
                                        className="drop_item"
                                        onDragLeave={(e) => dragLeaveHandler(e)}
                                        onDragStart={(e) => dragStartHandler(e, amenities, value)}
                                        onDragEnd={(e) => dragEndHandler(e)}
                                        draggable={true}
                                        key={value.id}
                                    >
                                        {value.name}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
                {boards2.map((value) => {
                    return (
                        <div className="Board" key={value.id} onDragOver={(e) => dragOverHandler(e)} onDrop={(e) => DropHandler(e, value, 1)}>
                            <div className="Board_title" key={value.id}>
                                {value.title}
                            </div>
                            {popularAmenities.map((pop) => {
                                return (
                                    <div
                                        className="drop_item"
                                        onDragLeave={(e) => dragLeaveHandler(e)}
                                        onDragStart={(e) => dragStartHandler(e, popularAmenities, pop)}
                                        onDragEnd={(e) => dragEndHandler(e)}
                                        draggable={true}
                                        key={pop.id}
                                    >
                                        {pop.name}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
            <ListBtn>
                {loading ? (
                    <LoadingButton loading loadingPosition="start" startIcon={<SaveIcon />} variant="outlined">
                        Submit
                    </LoadingButton>
                ) : (
                    <Button onClick={onSave} variant="contained" color="success" disabled={!selectedGroup}>
                        Submit
                    </Button>
                )}
            </ListBtn>
        </Wrapper>
    );
};

export default PopularAmenity;
