import { getHotelFilter, setHFPage } from "../../store/hotel-table-filer.js";
import { useContext, useState, Fragment, useEffect } from "react";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import { useDispatch, useSelector } from "react-redux";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import REACT_APP from "../../environment.js";
import Spinner from "react-spinner-material";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import { Message } from "./Context.js";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Action from "./Action.js";
import axios from "axios";

const headCells = [
    { id: "id", numeric: false, label: "Id", align: "left" },
    { id: "name", numeric: true, label: "Name", align: "left" },
    {
        id: "action",
        numeric: true,
        label: "Actions",
        align: "center",
        renderCell: () => {
            return <></>;
        },
    },
];

function EnhancedTableHead() {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={headCell.align}>
                        {headCell.id === "action" && <>&nbsp; &nbsp;&nbsp; &nbsp;</>}
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = { rowCount: PropTypes.number.isRequired };

export default function EnhancedTable({ setIsWaiting, setBookingType }) {
    const [message] = useContext(Message);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState([{ id: 1, name: "all" }, { id: 2, name: "any" }]);
    const [bookingTypes, setBookingTypes] = useState([]);
    const [dataLength, setDataLength] = useState(0);

    const dispatch = useDispatch();
    const hotelFiler = useSelector(getHotelFilter);
    const rowsPerPage = 10;
    const [page, setPage] = useState(hotelFiler.page);

    const getHotels = async () => {
        setLoading(true);
        const searchParams = new URLSearchParams();
        searchParams.append("page", page);
        searchParams.append("limit", rowsPerPage);

        await axios
            .get(`${REACT_APP.URL}/api/hdp/tts/adm/hotel/booking-type?${searchParams.toString()}`, {
                headers: { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.CLIENTID, "client-secret": REACT_APP.SECRET },
            })
            .then((res) => {
                setData(res.data.data);
                setDataLength(res.data.data.length);
                setLoading(false);
                setError(false);
                setBookingType(res.data.data);
                setBookingTypes(res.data.data);
            })
            .catch((err) => {
                console.log(err);
                setError(true);
                setLoading(false);
            });
    };

    const handleChangePage = (event, newPage) => {
        dispatch(setHFPage(newPage));
        setPage(newPage);
    };

    useEffect(() => {
        getHotels();
    }, [message, page]);

    return (
        <Fragment>
            <div style={{ display: "flex", alignItems: "baseline", flexWrap: "wrap", marginBottom: "10px", marginTop: "65px" }}>
            </div>
            {loading ? (
                <div style={{ marginTop: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                </div>
            ) : (
                <Box sx={{ width: "100%" }}>

                    {!error && data.length === 0 && (
                        <Alert variant="outlined" severity="warning">
                            No booking types found
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="outlined" severity="error">
                            Could not get data
                        </Alert>
                    )}

                    {(data.length >= 1 && !error) && (
                        <Paper sx={{ width: "100%", mb: 2 }}>
                            <TableContainer>
                                <Table sx={{ minWidth: 750, px: 2 }} aria-labelledby="tableTitle" size="medium">
                                    <EnhancedTableHead rowCount={data.length} />
                                    <TableBody>
                                        {data.types?.map((row) => {
                                            return (
                                                <TableRow hover tabIndex={-1} key={row.id}>
                                                    <TableCell align="left">{row.id}</TableCell>
                                                    <TableCell align="left">{row.name}</TableCell>
                                                    <TableCell align="center">
                                                        <Action
                                                            id={row.id}
                                                            status={row.status}
                                                            data={row}
                                                            name={row.name}
                                                            getAllHotels={getHotels}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                component="div"
                                count={dataLength}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        </Paper>
                    )}
                </Box>
            )}
        </Fragment>
    );
}
