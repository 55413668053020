/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import REACT_APP from "../../../environment";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { toast } from "react-toastify";
import Spinner from "../../Company/Spinner";

export default function deleteLang({
  id,
  handleMenuClose,
  getData,
}) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [wait, setwait] = React.useState(false);
  const [waittext, setwaittext] = React.useState("");
  const [valueSpinner, setValueSpinner] = React.useState(0);

  const blocked = async () => {
    try {
      setwait(true);
      setwaittext("in progress");
      setValueSpinner(100);
      await axios.delete(`${REACT_APP.COMMON_API}/tts/api/v01/languages/${id}`, {
headers: { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.COMMON_ID, "secret": REACT_APP.COMMON_SECRET },
      });
      setwaittext("table is being updated");
      await getData().catch((e) => console.log(e));
      handleClose();
      toast.success("Success", { autoClose: 2000 });
      handleMenuClose();
    } catch (err) {
      console.log(err);
      handleClose();
      toast.error("Something went wrong");
    }
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>delete</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete this language</DialogTitle>
        {wait ? (
          <Spinner value={valueSpinner} />
        ) : (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to delete this language?
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          {wait ? (
            <div
              style={{
                width: "100%",
                color: "#777",
                fontSize: "0.8em",
                padding: "0 20px",
              }}
            >
              {waittext}
            </div>
          ) : (
            <>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={blocked}>Delete</Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
