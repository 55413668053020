import React from "react";
import Table from "./Table";
import AddLang from "./AddLang";
import "./style.css";
import MessageProvider from "./Context";

const Hotel = () => {
  return (
    <div className="container">
      <MessageProvider>
        <Table />
        <AddLang />
      </MessageProvider>
    </div>
  );
};

export default Hotel;
