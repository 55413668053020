import React, { useContext } from "react";
import { Message } from "./Context";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import REACT_APP from "../../../environment";
import { TextField } from "formik-material-ui";
import { Grid } from "@mui/material";
import axios from "axios";
import regex from "tts-hrp-val-lib";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function TransitionsModal() {
  const [, setMessage] = useContext(Message);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const validation = Yup.object({
    name: Yup.string()
      .required("Field is required")
      .matches(regex.text, { message: "Invalid value" }),
  });
  const emptyData = { name: "" };

  const onSubmit = async (values) => {
    setLoading(true);
    setMessage(true);
    await axios
      .post(`${REACT_APP.COMMON_API}/tts/api/v01/languages`, values, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "client-id": REACT_APP.COMMON_ID,
          secret: REACT_APP.COMMON_SECRET,
        },
      })
      .then(() => {
        setLoading(false);
        setMessage(false);
        toast.success("Success", { autoClose: 2000 });
        handleClose();
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        toast.error("An error occurred");
      });
  };

  return (
    <div className="addhotel">
      <Button onClick={handleOpen} variant="contained">
        Add Language
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 500 } }}
      >
        <Fade in={open}>
          <Box sx={style} className="addhotelmodal">
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              className="marginBottom"
            >
              Enter language
            </Typography>
            <Formik
              initialValues={emptyData}
              validationSchema={validation}
              onSubmit={async (values) => {
                onSubmit(values);
                return new Promise((res) => setTimeout(res, 500));
              }}
            >
              {({ values, errors }) => (
                <Form autoComplete="off" className="mt-4">
                  <Grid container spacing={4}>
                    <Grid item xs={12} className="marginBottom">
                      <Field
                        fullWidth
                        label="Language name"
                        name="name"
                        component={TextField}
                        autoComplete="off"
                        type="text"
                      />
                    </Grid>
                  </Grid>

                  <div className="left_right">
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      color="error"
                    >
                      Close
                    </Button>
                    {loading ? (
                      <LoadingButton
                        loading
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        color="success"
                      >
                        Submit
                      </LoadingButton>
                    ) : (
                      <Button
                        type="submit"
                        className="mt-5"
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        color="success"
                      >
                        Submit
                      </Button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
