import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { Grid } from "@mui/material";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import ENV from "../../../environment";
import { useParams } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function TransitionsModal({ getTableData }) {
  const { hotelid } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const validate = Yup.object({
    address1: Yup.string().required("Address 1 is required"),
    address2: Yup.string().optional("Address 2 is optional"),
    district: Yup.string().required("District is required"),
    city: Yup.string().required("City is required"),
    region: Yup.string().required("Region is required"),
    zip_code: Yup.string().optional("Zip Code is optional"),
    country: Yup.string().required("Country is required"),
  });

  const getData = async () => {
    await axios
      .get(`${ENV.URL}/api/hdp/tts/adm/hotel/address/${hotelid}`, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "client-id": ENV.CLIENTID,
          "client-secret": ENV.SECRET,
        },
      })
      .then((res) => {
        setLoading(false);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.errors[0]?.message || "An error occurred"
        );
      });
  };

  const onUpdate = async (values) => {
    setLoading(true);
    const body = {
      address1: values?.address1,
      address2: !values?.address2.length ? "" : values.address2,
      city: values?.city,
      district: values?.district,
      region: values?.region,
      zip_code: !values?.zip_code.length ? "" : values.zip_code,
      country: values?.country,
    };
    await axios
      .put(`${ENV.URL}/api/hdp/tts/adm/hotel/address/${hotelid}`, body, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "client-id": ENV.CLIENTID,
          "client-secret": ENV.SECRET,
        },
      })
      .then((res) => {
        toast.success("Updated successfully", { autoClose: 2000 });
        handleClose();
        getTableData();
        getData();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.errors[0]?.message || "An error occurred"
        );
      });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <Button
        onClick={handleOpen}
        title="Click"
        size="large"
        variant="contained"
        color="warning"
      >
        Update Address info
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 500 } }}
      >
        <Fade in={open}>
          {data && (
            <Box sx={style} className="modal_res">
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                className="marginBottom"
              >
                Update Address info
              </Typography>

              <Formik
                initialValues={data}
                validationSchema={validate}
                onSubmit={async (values) => {
                  onUpdate(values);
                }}
              >
                {({ values, errors }) => (
                  <Form autoComplete="off" className="mt-4">
                    <>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <Field
                            fullWidth
                            name="address1"
                            size="small"
                            component={TextField}
                            label={"Address 1"}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            fullWidth
                            name="address2"
                            size="small"
                            component={TextField}
                            label={"Address 2"}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            fullWidth
                            name="district"
                            size="small"
                            component={TextField}
                            label={"District"}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            fullWidth
                            name="city"
                            size="small"
                            component={TextField}
                            label={"City"}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            fullWidth
                            name="region"
                            size="small"
                            component={TextField}
                            label={"Region"}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            fullWidth
                            name="zip_code"
                            size="small"
                            component={TextField}
                            label={"Zip Code"}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            fullWidth
                            name="country"
                            size="small"
                            component={TextField}
                            label={"Country"}
                          />
                        </Grid>
                      </Grid>
                    </>
                    <div className="right_left">
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handleClose}
                      >
                        Close
                      </Button>
                      <>
                        {loading ? (
                          <LoadingButton
                            loading
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            color="success"
                          >
                            Submit
                          </LoadingButton>
                        ) : (
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            color="success"
                          >
                            Submit
                          </Button>
                        )}
                      </>
                    </div>
                  </Form>
                )}
              </Formik>
            </Box>
          )}
        </Fade>
      </Modal>
    </div>
  );
}

export default React.memo(TransitionsModal);
